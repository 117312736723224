import { getContext, call, put, takeEvery } from 'redux-saga/effects';
import { patterns, reject, resolve } from 'actions/accountMetrics';
import { reportError } from 'helpers/error-reporting';

export function* fetchAccountMetrics(action) {
  const {
    payload: { metric },
  } = action || { payload: {} };
  try {
    const startDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    const endDate = new Date();

    const api = yield getContext('api');
    const { data: results } = yield call(
      api.getAccountMetrics,
      startDate.toISOString(),
      endDate.toISOString(),
      metric
    );

    yield put(resolve(results));
  } catch (error) {
    yield call(reportError, error);
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeEvery([patterns.callApi], fetchAccountMetrics);
}
