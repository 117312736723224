export const USER_SEATS_ENTITLEMENT = 'INVITE_USER';
export const PREMIUM_INTEGRATIONS_ENTITLEMENT = 'PREMIUM_INTEGRATIONS';

export const ENTITLEMENTS_CAPTIONS = {
  maus: 'monthly web active users (MAUs)',
  mtus: 'monthly tracked users (MTUs)',
  messages: 'monthly messages',
  emails: 'emails sent',
  mausOnly: 'monthly active users (MAUs)',
  [USER_SEATS_ENTITLEMENT]: 'user seats',
  [PREMIUM_INTEGRATIONS_ENTITLEMENT]: 'premium integrations',
};

export const PLANS_AND_PACKAGES_PRICING_URL = 'https://www.appcues.com/pricing';

export const ENTITLEMENTS_DESCRIPTIONS = {
  [PREMIUM_INTEGRATIONS_ENTITLEMENT]:
    'The following premium integrations can be added to your plan: HubSpot, Marketo, Salesforce, Slack, Zendesk Support',
};

export const SUPPORTED_ENTITLEMENTS = [
  USER_SEATS_ENTITLEMENT,
  PREMIUM_INTEGRATIONS_ENTITLEMENT,
];
