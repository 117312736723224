export const ENTITLEMENTS = {
  ANALYTICS_LOOKBACK: 'ANALYTICS_LOOKBACK',
  BANNERS: 'BANNERS',
  CHECKLISTS_ACCESS: 'CHECKLISTS_ACCESS',
  CHECKLISTS: 'CHECKLISTS',
  CTT: 'CTT',
  CUSTOM_CSS: 'CUSTOM_CSS',
  CUSTOM_WEBHOOKS: 'CUSTOM_WEBHOOKS',
  EDITOR_ROLE: 'EDITOR_ROLE',
  EVENT_BROADCASTER: 'EVENT_BROADCASTER',
  EVENT_TRIGGERING_ACCESS: 'EVENT_TRIGGERING_ACCESS',
  FLOWS: 'FLOWS',
  FREQUENCY_ACCESS: 'FREQUENCY_ACCESS',
  GOALS: 'GOALS',
  HUBSPOT_ACCESS: 'HUBSPOT_ACCESS',
  INVITE_USER: 'INVITE_USER',
  LAUNCHPAD_BRANDING: 'LAUNCHPAD_BRANDING',
  LAUNCHPAD_KB_INTEGRATIONS: 'LAUNCHPAD_KB_INTEGRATIONS',
  LAUNCHPADS_ACCESS: 'LAUNCHPADS_ACCESS',
  LAUNCHPADS: 'LAUNCHPADS',
  LOCALIZATION_ACCESS: 'LOCALIZATION_ACCESS',
  MARKETO_ACCESS: 'MARKETO_ACCESS',
  PINS: 'PINS',
  PREMIUM_INTEGRATIONS: 'PREMIUM_INTEGRATIONS',
  PRIORITY_ACCESS: 'PRIORITY_ACCESS',
  PUBLISHED_EXPERIENCES: 'PUBLISHED_EXPERIENCES',
  SALESFORCE_ACCESS: 'SALESFORCE_ACCESS',
  SEGMENTS: 'SEGMENTS',
  SLACK_ACCESS: 'SLACK_ACCESS',
  SSO_ACCESS: 'SSO_ACCESS',
  WORKFLOWS: 'WORKFLOWS',
  ZENDESK_ACCESS: 'ZENDESK_ACCESS',
  NPS_ACCESS: 'NPS_ACCESS',
  PUBLISHED_EXPERIENCES_WORKFLOWS: 'PUBLISHED_EXPERIENCES_WORKFLOWS',
  PINS_ACCESS: 'PINS_ACCESS',
};
